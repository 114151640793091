<!--
 * @Description: 我的订单页面组件
 * @Author: hai-27
 * @Date: 2020-02-20 17:21:54
 * @LastEditors: hai-27
 * @LastEditTime: 2020-02-27 13:36:27
 -->
<template>
	<div class="uc-box uc-main-box">
		<div class="uc-content-box order-list-box">
			<!-- 我的订单头部 -->
			<div class="box-hd">
				<h1 class="title">我的订单</h1>
				<div class="more clearfix">
					<ul class="filter-list ">
						<li class="first" v-for="(item,index) in navlist" :key="index"
							:class="index == queryIndex?'active':''" @click="clicknav(item,index)">
							<a href="javascript:void(0);">{{item.name}}</a>
						</li>
					</ul>
					<div class="clearfix">

					</div>
				</div>
				<div class="menu_search p">

					<input class="sea_ol" type="text" id="search_key" v-model="selectvalue" placeholder="商品名称，订单编号"
						placeholder-class="sea_ol_placeholder" />
					<input class="sea_et" type="submit" value="查询" style="cursor: pointer;" @click="chaxun" />


				</div>
				<div class="menu_search p paytool">
					<div class="item">
						支付方式：
						<el-select v-model="payType" placeholder="请选择">
							<el-option v-for="(item,index) in payTypeArr" :key="index" :label="item.Name"
								:value="item.Id">
							</el-option>
						</el-select>
					</div>
					<div class=" item banklist" v-if="payType==3">
						支付银行卡：
						<el-select v-model="bankId" placeholder="请选择">
							<el-option v-for="(item,index) in blist" :key="index" :label="item.BankCard"
								:value="item.Id">
							</el-option>
						</el-select>
					</div>
				</div>

			</div>
			<!-- 我的订单头部END -->

			<!-- 我的订单主要内容 -->
			<div class="box-bd" v-if="querylist.items.length>0">


				<div class="orderbook-list">
					<div class="book-tit">
						<ul>
							<li class="sx1">商品信息</li>
							<li class="sx2">单价</li>
							<li class="sx3">数量</li>
							<li class="sx4">订单总价</li>
							<li class="sx5 s5clic">订单状态</li>
						</ul>
					</div>
				</div>
				<div class="order-alone-li lastset_cm" v-for="(item,index) in querylist.items" :key="index">
					<table width="100%" border="" cellspacing="" cellpadding="" id="ordertable_13">
						<tr class="time_or">
							<td colspan="6">
								<div class="fl_ttmm">
									<span class="time-num">下单时间：<em class="num">{{item.CreateTime}}</em></span>
									<span class="time-num">订单编号：<em class="num">{{item.OrderNo}}</em></span>
									<span class="time-num">卖家：
										<a href="javascript:[0];">
											<em class="num">{{item.ShopName}}<i class="ear"></i></em></a></span>
									<div class="paysoon">
										<a href="javascript:[0];" v-if="item.IsPay==0" class="ps_lj"
											@click="orderpay(item.OrderNo)">立即支付</a>
										<a href="javascript:[0];" v-if="item.State==2" class="ps_lj"
											@click="shouhuo(item.OrderNo)">确认收货</a>
										<a data-v-23c765ea="" href="javascript:[0];" v-if="item.State==2"
											@click="toUrl('ViewLogistics',{wuliu:item.loglist[0].LogisticsNo,code:item.loglist[0].ExpressCode})"
											class="btn btn-small btn-line-gray">查看物流</a>
										<!-- <a class="consoorder" href="javascript:;" onClick="cancel_order(13)">取消订单</a> -->
									</div>
								</div>
								<div class="fr_ttmm"></div>
							</td>
						</tr>
						<tr class="conten_or" v-for="(good,gindex) in item.dlist " :key="gindex">
							<td class="sx1">
								<div class="shop-if-dif">
									<div class="shop-difimg">
										<img :src="getUrl(good.GoodsImg) " width="60" height="60" />
									</div>
									<div class="shop_name">
										<a href="/index.php/Home/Goods/goodsInfo/id/23.html">{{good.GoodsName}}</a>
									</div>
								</div>
							</td>
							<td class="sx2"><span>￥</span><span>{{good.Price}}元</span></td>
							<td class="sx3">
								<span>x{{good.Num}}</span>
							</td>
							<td class="sx4" rowspan="2" v-if="gindex==0">
								<div class="pric_rhz">
									<p class="d_pri"><span>￥</span><span>{{item.Total}}</span></p>
									<p class="d_yzo"><a href="javascript:void(0);"></a></p>
								</div>
							</td>
							<td class="sx5" rowspan="2" v-if="gindex==0">
								<div class="detail_or">
									<p class="d_yzo">
										{{item.State==0?"等待付款":item.State==1?"待发货":item.State==2?"待收货":item.State==9?"已完成":""}}
									</p>
									<p>
										<a data-v-23c765ea="" href="javascript:[0];"
											@click="ToOrderDetail(item.Id)">查看详情</a>
									</p>
								</div>
							</td>

						</tr>
					</table>
				</div>
			</div>
			<!-- <div>
					<ul class="order-list">
						<li data-v-1df9de88="" class="uc-order-item uc-order-item-pay"
							v-for="(item,index) in querylist.items" :key="index">
							<div data-v-23c765ea="" data-v-1df9de88="" class="order-detail">
								<div data-v-23c765ea="" class="order-summary">
									<div data-v-23c765ea="" class="order-status">
										{{item.State==0?"等待付款":item.State==1?"待发货":item.State==2?"待收货":item.State==9?"已完成":""}}
									</div>
								</div>
								
								<table data-v-23c765ea="" class="order-detail-table">
									<thead data-v-23c765ea="">
										<tr data-v-23c765ea="">
											<th data-v-23c765ea="" class="col-main">
												<p data-v-23c765ea="" class="caption-info">
													下单时间：{{item.CreateTime}}
													<span data-v-23c765ea="" class="sep">|</span>
													{{item.UserName}}
													<span data-v-23c765ea="" class="sep">|</span>
													订单编号：<a data-v-23c765ea="" href="javascript:[0];"
														class="ordernum">{{item.OrderNo}}</a>
													<span data-v-23c765ea="" class="sep">|</span>
													卖家：有限公司
												</p>
											</th>
											<th data-v-23c765ea="" class="col-sub">
												<p data-v-23c765ea="" class="caption-price">
													商品总价：
													<span data-v-23c765ea="" class="num">{{item.Total}}</span>元
												</p>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td class="order-items">
												<ul class="goods-list">
													<li v-for="(good,gindex) in item.dlist " :key="gindex">
														<div class="figure figure-thumb"><a href="javascript:[0];"
																target="_blank"><img :src="getUrl(good.GoodsImg) "
																	width="80" height="80"></a>
														</div>
														<p class="name"><a href="javascript:[0];"
																target="_blank">{{good.GoodsName}}</a>
														</p>
														<p class="price">
															{{good.Price}}元 × {{good.Num}}
														</p>
													</li>
												</ul>
											</td>
											<td data-v-23c765ea="" class="order-actions">
												<a data-v-23c765ea="" href="javascript:[0];" v-if="item.IsPay==0"
													@click="orderpay(item.OrderNo)"
													class="btn btn-small btn-primary">立即付款</a>
												<a data-v-23c765ea="" href="javascript:[0];" v-if="item.State==2"
													@click="shouhuo(item.OrderNo)"
													class="btn btn-small btn-primary">确认收货</a>
												<a data-v-23c765ea="" href="javascript:[0];" v-if="item.State==2"
													@click="toUrl('ViewLogistics',{wuliu:item.loglist[0].LogisticsNo,code:item.loglist[0].ExpressCode})"
													class="btn btn-small btn-line-gray">查看物流</a>

												<a data-v-23c765ea="" href="javascript:[0];"
													class="btn btn-small btn-line-gray"
													@click="toUrl('OrderDetail',{id:item.Id})">订单详情</a>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</li>
					</ul>
				</div> -->
		</div>
		<!-- 我的订单主要内容END -->

		<!-- 订单为空的时候显示的内容 -->
		<div class="order-empty" v-if="querylist.items.length<=0">
			<!-- v-else -->
			<div class="empty">
				<h2>您的订单还是空的！</h2>
				<p>快去购物吧！</p>
			</div>
		</div>
		<!-- 订单为空的时候显示的内容END -->
		<!-- </div> -->
		<el-dialog title="交易确认" :visible.sync="dialogVisible" width="80%" destroy-on-close
			:close-on-click-modal="false">
			<div>
				<el-form ref="form1" :model="protocolPayConfirmQuery" label-width="100px">
					<el-form-item label="订单号">
						<el-input v-model="protocolPayConfirmQuery.token" :disabled="true"></el-input>
					</el-form-item>
					<el-form-item label="协议号">
						<el-input v-model="protocolPayConfirmQuery.protocol" :disabled="true"></el-input>
					</el-form-item>
					<el-form-item label="短信验证码">
						<el-input v-model="protocolPayConfirmQuery.smsCode"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" style="background-color: #0C6A6A;border: none;" :disabled="IsChecked"
							@click="SubmitprotocolPayConfirm">提交</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import axios from 'axios'

	export default {
		name: 'Order',
		data() {
			return {
				orders: [], // 订单列表
				total: [], // 每个订单的商品数量及总价列表
				queryIndex: 0,
				selectvalue: '',
				navlist: [{
						name: '全部订单',
						queryState: null,
					},
					{
						name: '待付款',
						queryState: '0',
					},
					{
						name: '待发货',
						queryState: '1',
					},
					{
						name: '待收货',
						queryState: '2',
					},
					// {
					// 	name: '待评价',
					// 	queryState: '3',
					// },
					// {
					// 	name: '待售后',
					// 	queryState: '4',
					// },
					// {
					// 	name: '售后完成',
					// 	queryState: '5',
					// }
				],
				query: {
					"page": 1,
					"limit": 10,
					"queryState": '',

				},
				querylist: {
					items: [],
					total: 0,
				},
				blist: [],
				bankId: '',
				protocolPayConfirmQuery: {
					token: '',
					protocol: '',
					smsCode: ''
				},
				dialogVisible: false,
				IsChecked: false,
				payType: 4,
				payTypeArr: [{
					Id: 3,
					Name: '快捷支付'
				}, {
					Id: 4,
					Name: '易票联扫码支付'
				}]
			};
		},
		created() {
			this.orderList(0)
			this.GetUserBankList()
		},
		methods: {
			chaxun() {
				this.query.queryName = this.selectvalue
				this.orderList(0)
			},
			async shouhuo(orderNo) {
				var data = []
				data.push(orderNo)
				const res = await this.postdata('/Order/OrderReceiving', data)
				if (res.code == 200) {
					this.notifySucceed('确认收货成功');
					this.orderList(0)

				} else {
					this.notifyError(res.msg);
				}

			},
			wuliu() {

			},

			clicknav(item, index) {
				this.queryIndex = index
				this.query.queryState = item.queryState
				this.orderList(0)
			},
			async orderList(type) {
				if (type == 0) {
					this.query.page = 0
				}
				this.query.page++
				const res = await this.postdata('/Order/OrderList', this.query)
				if (res.code == 200) {
					this.querylist.total = res.data.total
					if (type >= 1) {
						this.querylist.items = this.querylist.items.concat(res.data.items)
					} else {
						this.querylist.items = res.data.items
					}

				}
			},

			async orderpay(orderno) {
				var payType = this.payType
				if (payType == 4) {
					this.$router.push({
						path: "/payQrCode",
						query: {
							OrderNo: orderno
						}
					})
					return;
				}

				if (payType == 3 && this.bankId == '') {
					this.notifyError('请选择支付的银行卡');
					return false
				}
				var data = {
					OrderNo: orderno,
					PayType: payType,
					BankId: this.bankId
				}
				const res = await this.postdata('/Order/OrderAgainPay', data)
				console.log('res', res)
				if (res.code == 200) {
					var result = res.data
					console.log(result)
					if (result.returnCode != '0000') {
						this.notifyError(result.returnMsg);
					} else {
						this.protocolPayConfirmQuery.token = result.token
						this.protocolPayConfirmQuery.protocol = this.blist.find(it => it.Id == this.bankId).protocol
						this.protocolPayConfirmQuery.smsCode = ""
						this.dialogVisible = true
					}
				}
			},
			async GetUserBankList() {
				const res = await this.postdata('/User/UserBankList', {
					page: 1,
					limit: 100,
					isLike: 1
				})
				if (res.code == 200) {
					this.blist = res.data.items
				}
			},
			async SubmitprotocolPayConfirm() {
				this.IsChecked = true
				const res = await this.postdata('/Order/epay_protocolPayConfirm', this.protocolPayConfirmQuery)
				this.IsChecked = false
				if (res.code == 200) {
					var result = res.data
					console.log(result)
					if (result.returnCode != '0000') {
						this.notifyError(result.returnMsg);
					} else {
						this.notifySucceed('支付处理中');
						this.dialogVisible = false
						this.orderList(0)
					}
				}
			},
			ToOrderDetail(id) {
				this.$router.push({
					name: 'OrderDetail',
					query: {
						id: id
					}
				})
			}



		}
	};
</script>
<style scoped lang="less">
	/* 订单为空的时候显示的内容CSS */
	.clearfix {
		clear: both;
	}

	.clearfix:after,
	.clearfix:before {
		content: " ";
		clear: both;
	}

	.uc-box .order-empty {
		margin: 0 auto;
	}

	.uc-box .order-empty .empty {
		height: 300px;
		padding: 0 0 130px 455px;
		margin: 65px 0 0;
		background: url(../../assets/imgs/cart-empty.png) no-repeat 32px 0;
		color: #b0b0b0;
		overflow: hidden;
	}

	.uc-box .order-empty .empty h2 {
		margin: 70px 0 15px;
		font-size: 36px;
	}

	.uc-box .order-empty .empty p {
		margin: 0 0 20px;
		font-size: 20px;
	}


	.uc-box {
		background: #fff
	}

	.uc-box .uc-content-box {
		margin: 0 48px
	}

	.uc-box .uc-content-box .box-hd .title {
		margin: 0;
		font-size: 30px;
		font-weight: 400;
		line-height: 68px;
		color: #757575
	}

	.uc-box .uc-content-box .box-hd .more {
		border-bottom: 0;
	}

	.uc-box .uc-content-box .box-hd .filter-list {
		float: left;
		margin: 0;
		padding: 18px 0;
		list-style-type: none;
		font-size: 22px;
		line-height: 1.5;
	}

	.uc-box .uc-content-box .box-hd .filter-list a {
		color: #333;
		cursor: pointer;
		padding: 0 20px;
		text-align: center;
	}

	.uc-box .uc-content-box .box-hd .filter-list li {
		float: left;
		margin: 0 100px 0 0;
		border-bottom: 3px solid #fff;
		color: #757575
	}

	.uc-box .uc-content-box .box-hd .filter-list li.first {
		padding-left: 0;
		border-left: 0
	}

	.uc-box .uc-content-box .box-hd .filter-list .active {
		border-bottom: 3px solid #e23435;
		transition: all 0.2s;
	}

	.uc-box .uc-content-box .box-hd .filter-list li.active,
	.uc-box .uc-content-box .box-hd .filter-list li.active a,
	.uc-box .uc-content-box .box-hd .filter-list li.tab-active,
	.uc-box .uc-content-box .box-hd .filter-list li.tab-active a {
		color: #e23435;
	}

	.hide {
		display: none !important
	}

	.uc-order-item {
		position: relative
	}



	/* new list */
	.orderbook-list {
		position: relative;
	}

	.book-tit {
		margin-top: 20px;
		display: block;
		overflow: hidden;
		width: 100%;
		background: #f5f5f5;
		height: 38px;
		line-height: 38px;
	}

	.book-tit ul li {
		float: left;
		text-align: center;
		position: relative;
		font-size: 14px;
		color: #666666;
	}

	.sx1 {
		width: 400px;
	}

	.sx2 {
		width: 121px;
	}

	.sx3 {
		width: 121px;
	}

	.sx4 {
		width: 121px;
	}

	.sx5 {
		width: 121px;
	}

	.sx6 {
		width: 121px;
	}

	.order-alone-li table {
		margin-top: 10px;
		margin-bottom: 30px;
		border: 1px solid #dadada;
		border-collapse: collapse;
	}

	.order-alone-li .time_or {
		border-bottom: 0;
	}

	.order-alone-li .time_or td {
		background: #f5f5f5;
		padding: 6px 15px;
	}

	.order-alone-li .time_or td .fl_ttmm {
		position: relative;
		min-height: 26px;
		line-height: 26px;
	}

	.order-alone-li .time_or td .time-num {
		color: #999999;
	}

	.order-alone-li .time_or td span {
		font-size: 14px;
	}

	.order-alone-li .time_or td .num {
		color: #333333;
		margin-right: 40px;
	}

	.paysoon {
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}

	.paysoon .ps_lj {
		display: inline-block;
		height: 26px;
		line-height: 26px;
		width: 72px;
		text-align: center;
		background: #e23435;
		color: #fff;
		border-radius: 10px;
	}

	.paysoon .consoorder {
		color: #e23435;
	}

	.order-alone-li .conten_or {
		border-top: 0;
	}

	.order-alone-li .conten_or .sx1 {
		border-right: 0;
	}

	.lastset_cm .conten_or td {
		padding: 10px 0;
		vertical-align: top;
	}

	.order-alone-li .conten_or td {
		padding: 26px 0;
		border: 1px solid #dadada;
	}

	.shop-if-dif {
		padding: 0 15px;
		overflow: hidden;
		display: flex;
		align-items: center;
	}

	.lastset_cm .shop-if-dif .shop-difimg {
		width: 60px;
		height: 60px;
	}

	.lastset_cm .shop-if-dif .shop_name {
		margin-top: 0px;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
		word-break: break-all;
	}

	.shop-if-dif .shop_name {
		float: left;
		margin-top: 28px;
		width: 260px;
	}

	.shop-if-dif .shop_name :hover {
		color: #e23435;
	}

	.shop_name,
	.shop_price {
		width: 157px;
		margin-left: 20px;
		overflow: hidden;
		line-height: 18px;
		font-size: 14px;
		color: #666;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		word-break: break-word;
	}

	.order-alone-li .conten_or .sx2 {
		border-left: 0;
		font-size: 16px;
	}

	.order-alone-li .conten_or .sx2,
	.order-alone-li .conten_or .sx3,
	.order-alone-li .conten_or .sx4,
	.order-alone-li .conten_or .sx6 {
		text-align: center;
		font-size: 16px;
		line-height: 30px;
		vertical-align: middle;
	}

	.order-alone-li .conten_or .sx2 {
		color: #000;
	}

	.order-alone-li .conten_or .sx4 {
		color: #000;

	}

	.order-alone-li .conten_or .sx5 {
		font-size: 14px;
		text-align: center;
		color: #000;
		line-height: 30px;
		vertical-align: middle;
	}

	.order-alone-li .conten_or .sx5 a:hover {
		color: #e23435;
	}

	.order-alone-li .conten_or .sx5 .detail_or .d_yzo {
		font-size: 16px !important;
		color: #666;
	}

	.lastset_cm .conten_or td {
		padding: 10px 0;
		vertical-align: top;
	}

	.order-alone-li .conten_or td {
		padding: 26px 0;
		border: 1px solid #dadada;
	}

	em {
		font-style: normal;
	}














	.order-list-box .order-list {
		margin: 0;
		padding: 10px 0;
		list-style-type: none
	}

	.order-list-box .order-list .uc-order-item {
		margin-bottom: 20px;
		border: 1px solid #e0e0e0
	}

	.order-list-box .order-list .uc-order-item .order-remove {
		position: absolute;
		right: 15px;
		top: 15px;
		height: 20px;
		width: 20px;
		cursor: pointer;
		opacity: 0;
		filter: alpha(opacity=0)\9
	}

	.order-list-box .order-list .uc-order-item .order-remove img {
		display: block;
		width: 100%
	}

	.order-list-box .order-list .uc-order-item .order-status {
		font-size: 18px
	}

	.order-list-box .order-list .uc-order-item:hover .order-remove {
		opacity: 1;
		filter: alpha(opacity=100)\9
	}

	.order-list-box .order-list .uc-order-item-finish {
		border-color: #b0b0b0
	}

	.order-list-box .order-list .uc-order-item-finish .order-desc,
	.order-list-box .order-list .uc-order-item-finish .order-status {
		color: #b0b0b0
	}

	.order-list-box .order-list .uc-order-item-finish .order-detail-table th {
		border-bottom: 1px solid #e0e0e0
	}

	.order-list-box .order-list .uc-order-item-finish .order-detail+.order-detail {
		border-top: 1px solid #b0b0b0
	}

	.order-list-box .order-list .uc-order-item-shipping {
		border-color: #83c44e
	}

	.order-list-box .order-list .uc-order-item-shipping .order-desc,
	.order-list-box .order-list .uc-order-item-shipping .order-status {
		color: #83c44e
	}

	.order-list-box .order-list .uc-order-item-shipping .order-detail-table th {
		background: #fbfff6;
		border-bottom: 1px solid #cfeeab
	}

	.order-list-box .order-list .uc-order-item-shipping .order-summary {
		background: #fbfff6
	}

	.order-list-box .order-list .uc-order-item-shipping .order-detail+.order-detail {
		border-top: 1px solid #83c44e
	}

	.order-list-box .order-list .uc-order-item-pay {
		border-color: #ff6700
	}

	.order-list-box .order-list .uc-order-item-pay .order-desc,
	.order-list-box .order-list .uc-order-item-pay .order-status {
		color: #ff6700
	}

	.order-list-box .order-list .uc-order-item-pay .order-detail-table th {
		background: #fffaf7;
		border-bottom: 1px solid #feccac
	}

	.order-list-box .order-list .uc-order-item-pay .order-summary {
		background: #fffaf7
	}

	.order-list-box .goods-list {
		margin: 0;
		padding: 10px 0;
		list-style-type: none
	}

	.order-list-box .goods-list li {
		position: relative;
		height: 44px;
		margin: 10px 0;
		padding: 18px 18px 18px 100px;
		line-height: 22px;
		color: #333
	}

	.order-list-box .goods-list .figure-thumb {
		position: absolute;
		left: 0;
		top: 0
	}

	.order-list-box .goods-list .name {
		margin: 0
	}

	.order-list-box .goods-list .name a {
		color: #333
	}

	.order-list-box .goods-list .name a:hover {
		color: #ff6700
	}

	.order-list-box .goods-list .price {
		margin: 0
	}

	.order-list-box .order-summary {
		padding: 25px 30px 1px
	}

	.order-list-box .order-detail-table {
		width: 100%
	}

	.order-list-box .order-detail-table th {
		height: 28px;
		padding: 0 30px 24px;
		border-bottom: 1px solid #e0e0e0;
		font-weight: 400;
		text-align: left;
		color: #757575;
		vertical-align: bottom
	}

	.order-list-box .order-detail-table th p {
		margin: 0
	}

	.order-list-box .order-detail-table th .sep {
		color: #e0e0e0
	}

	.order-list-box .order-detail-table th .num {
		height: 18px;
		margin-right: 5px;
		font-size: 28px;
		font-weight: 200;
		line-height: 1;
		color: #333
	}

	.order-list-box .order-detail-table th.col-main {
		padding-right: 10px
	}

	.order-list-box .order-detail-table th.col-sub {
		width: 240px;
		padding-left: 10px;
		text-align: right
	}

	.order-list-box .order-detail-table td {
		padding: 0 30px
	}

	.order-list-box .order-detail-table .order-actions {
		padding-top: 20px;
		text-align: right;
		vertical-align: top
	}

	.order-list-box .order-detail-table .order-actions .btn {
		display: block;
		margin: 0 0 10px auto
	}

	.order-list-box .btn-line-gray {
		border-color: #b0b0b0;
		background: #fff;
		color: #757575
	}

	.order-list-box .empty {
		margin: 40px 0;
		font-size: 18px;
		text-align: center;
		color: #b0b0b0
	}

	.btn {
		display: inline-block;
		width: 158px;
		height: 38px;
		padding: 0;
		margin: 0;
		border: 1px solid #b0b0b0;
		font-size: 14px;
		line-height: 38px;
		text-align: center;
		color: #b0b0b0;
		cursor: pointer;
		-webkit-transition: all .4s;
		transition: all .4s
	}

	.btn:hover {
		text-decoration: none;
		color: #b0b0b0
	}

	.btn:focus {
		outline: 0
	}

	.btn:active {
		-webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, .18);
		box-shadow: inset 0 2px 4px rgba(0, 0, 0, .18)
	}

	.btn[disabled] {
		border-style: dashed !important;
		border-color: #e0e0e0;
		background-color: #fff !important
	}

	.btn-disabled,
	.btn[disabled] {
		color: #b0b0b0 !important;
		cursor: default !important
	}

	.btn-disabled {
		background: #e0e0e0 !important;
		border-color: #e0e0e0 !important
	}

	.btn-small {
		width: 118px;
		height: 28px;
		font-size: 12px;
		line-height: 28px
	}

	.btn-large {
		width: 178px;
		height: 48px;
		line-height: 48px
	}

	.btn-biglarge {
		width: 298px;
		height: 52px;
		line-height: 52px;
		font-size: 16px
	}

	.btn-block {
		display: block;
		width: 100%;
		padding-left: 0;
		padding-right: 0
	}

	.btn-primary {
		background: #ff6700;
		border-color: #ff6700;
		color: #fff;
	}

	button.btn,
	input.btn {
		width: 160px;
		height: 40px
	}

	button.btn-small,
	input.btn-small {
		width: 120px;
		height: 30px
	}

	.ordernum {
		color: #757575;
	}

	/* 订单为空的时候显示的内容CSS END */

	/* 查询订单 */
	.menu_search .sea_ol {
		width: 250px;
		height: 30px;
		outline: none;
		padding: 0 6px;
		border: 2px solid #bebebe;
		vertical-align: middle;
		color: #000;
	}

	.menu_search .sea_ol_placeholder {
		color: #666;
	}

	.menu_search .sea_et {
		width: 50px;
		height: 32px;
		padding: 0 10px;
		margin-left: 10px;
		background: #f9f9f9;
		border: 2px solid #bebebe;
		color: #666;
		outline: none;
		vertical-align: middle;
	}

	.paytool {
		margin-top: 16px;
		display: flex;

		.item {}

		.banklist {
			margin-left: 16px;

		}
	}
</style>